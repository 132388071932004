import { createStore } from '@winvestor/wds'

export type Toast = {
  kind: string
  text: string
  delay?: number
  autoclose?: boolean
}

document.addEventListener('turbolinks:before-render', () => (state.wait = true))
document.addEventListener('turbolinks:redirect', () => (state.wait = true))
document.addEventListener('turbolinks:load', () => (state.wait = false))

const { state, onChange } = createStore({
  queue: [] as Toast[],
  wait: false,
})

const push = (toast: Toast) => {
  state.queue = [...state.queue, toast]
}

const shift = () => {
  if (state.wait) return

  return state.queue.shift()
}

export { state, onChange, push, shift }
