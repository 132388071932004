import { Cable, CreateMixin } from 'actioncable'
import CableReady from 'cable_ready'

export const tabIdentifier = window.crypto?.randomUUID?.() ?? ''
const cache = new Map<string, ReturnType<typeof createConnection>>()

const cacheKey = (
  consumerUrl: string,
  name: string,
  params: Record<string, any> = {}
) => `${consumerUrl}:${name}:${JSON.stringify(params)}`

const createConnection = (
  consumer: Cable,
  name: string,
  params: Record<string, any> = {},
  mixin?: CreateMixin
) => {
  let connected = false
  const waitUntilChannelSubscribed = (callback: () => void) => {
    if (connected) {
      callback()
    } else {
      setTimeout(() => {
        waitUntilChannelSubscribed(callback)
      }, 50)
    }
  }

  const channel = consumer.subscriptions.create(
    { channel: name, ...params },
    {
      received(data: CableReady.Data) {
        if (data.cableReady) {
          data = this.beforePerform(data) as CableReady.Data
          CableReady.perform(data.operations)
        }
      },
      connected() {
        connected = true
      },
      send(
        this: { consumer: ActionCable.Cable; identifier: string },
        data: Record<string, any>
      ) {
        waitUntilChannelSubscribed(() => {
          return this.consumer.send({
            command: 'message',
            identifier: this.identifier,
            data: JSON.stringify(data),
          })
        })
      },
      beforePerform(data: CableReady.Data) {
        return data
      },
      unsubscribe() {
        consumer.subscriptions.remove(channel)
        cache.delete(cacheKey(consumer.url, name, params))
        connected = false
      },
      ...mixin,
    }
  )

  return { channel, waitUntilChannelSubscribed }
}

const createChannel = (
  consumer: Cable,
  name: string,
  params: Record<string, any> = {},
  mixin?: CreateMixin
) => {
  if (!params.withoutIdentifier) {
    params.tab_identifier = tabIdentifier
  }

  delete params.withoutIdentifier

  const streamIdentifierMeta = document.querySelector(
    '[name="stream-identifier"]'
  )

  if (streamIdentifierMeta) {
    params.identifier = streamIdentifierMeta.getAttribute('value')
  }

  const key = cacheKey(consumer.url, name, params)

  if (cache.has(key)) return cache.get(key)!

  const connection = createConnection(consumer, name, params, mixin)
  cache.set(key, connection)

  return connection
}

export { createChannel }
