import { Controller } from 'stimulus'

const WDS_SELECTORS = [
  'wds-bottom-sheet',
  'wds-btn',
  'wds-checkbox',
  'wds-dnd-sort',
  'wds-dropzone',
  'wds-form',
  'wds-form-control',
  'wds-icon',
  'wds-input',
  'wds-option',
  'wds-radio',
  'wds-select',
  'wds-suggestion',
  'wds-swiper',
  'wds-tabs',
  'wds-textarea',
  'wds-toast',
  'wds-toasts-container',
  'wds-tooltip',
  'wds-transfer',
]
  .map((selector) => `${selector}:not(.hydrated)`)
  .join(',')

const preventDoublingCall = (el: Element, fn: () => void) => {
  let timeout: NodeJS.Timeout
  const waitUntilVDomReady = () => {
    if (el.closest(WDS_SELECTORS)) {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => {
        waitUntilVDomReady()
      }, 50)
    } else {
      fn()
    }
  }

  return waitUntilVDomReady
}

export const usePreventDoublingCall = (controller: Controller) => {
  controller.context.connect = preventDoublingCall(
    controller.element,
    controller.context.connect.bind(controller.context)
  )
  controller.context.disconnect = preventDoublingCall(
    controller.element,
    controller.context.disconnect.bind(controller.context)
  )
}
