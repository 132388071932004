import { Validator, ValidatorOptions } from '@winvestor/wds'

export const PieceCountValidator: Validator = {
  validate(value: string, id: string, opts: ValidatorOptions) {
    const el = document.querySelector<HTMLWdsFormControlElement>(`#${id}`)

    if (!el) return

    if (parseInt(value) > opts.max || parseInt(value) === 0 || !value) {
      el.setAttribute('errors', '[" "]')
    } else {
      el.removeAttribute('errors')
    }
  },
}
