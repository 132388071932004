import * as _ from 'lodash-es'

class Carrotquest {
  reload() {
    if (this.carrotquestReady) {
      window.carrotquest.messenger.enterCallback = null
      window.carrotquest.messenger._onDOMReadyCalled = false

      window.carrotquest.messenger.toStateNo()
      window.carrotquest.messenger.onDOMReadyCallback()
    }
  }

  setUser() {
    if (window.carrotquest && !_.isEmpty(this.currentUser)) {
      this.auth()
      this.setUserData()
    }
  }

  auth() {
    window.carrotquest.auth(
      'pl_' + this.currentUser.platform_id,
      this.currentUser.hash_id
    )
  }

  setUserData() {
    window.carrotquest.identify(this.userDataForIdentify)
  }

  get carrotquestReady() {
    return (
      window.carrotquest && 'undefined' !== typeof window.carrotquest.messenger
    )
  }

  get userDataForIdentify() {
    const data = {
      $name: this.currentUser.fullname,
      $phone: this.currentUser.phone,
    }

    return this.currentUser.email
      ? { ...data, $email: this.currentUser.email }
      : data
  }

  get currentUser() {
    return window.gon.current_user!
  }
}

const carrotquest = new Carrotquest()

export { carrotquest }
