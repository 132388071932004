import { createConsumer } from '@rails/actioncable'

const consumer = createConsumer() as ActionCable.Cable & {
  connection?: {
    isActive (): boolean
    isOpen (): boolean
  }
  subscriptions: {
    subscriptions: {
      identifier: string
    }[]
  }
}

export { consumer }
