import { addValidator } from '@winvestor/wds'
import { defineCustomElements } from '@winvestor/wds/loader'
import { ActiveRecordValidator } from '@/validators/active_record'
import { carrotquest } from '@/lib/carrotquest'
import { PieceCountValidator } from '@/validators/piece_count'

import turbolinks from 'turbolinks'
import mrujs from 'mrujs'

import { tabIdentifier } from '@/factories/cable_ready_channel'

import { CustomNavigationAdapter } from '@/lib/mrujs/custom_navigation_adapter'

import '@/channels'
import '@/controllers'

import '~/stylesheets/index.scss'

addValidator('ActiveRecord', ActiveRecordValidator)
addValidator('PieceCount', PieceCountValidator)

void defineCustomElements()

mrujs.inputChangeSelector += ', wds-checkbox[data-remote]'

if (!('mrujs' in window)) mrujs.start({ plugins: [CustomNavigationAdapter()] })
if (!('Turbolinks' in window)) turbolinks.start()

mrujs.navigationAdapter.disconnect?.()

window.history.scrollRestoration = 'manual'

carrotquest.setUser()

document.addEventListener('turbolinks:load', () => {
  carrotquest.reload()
})

window.historyBackOrVisit = (url: string) => {
  if (history.length > 1) {
    history.back()
  } else {
    Turbolinks.visit(url)
  }
}

document.addEventListener(
  'ajax:beforeSend',
  (e: Event) => {
    const { request } = (
      e as CustomEvent<ReturnType<typeof mrujs.FetchRequest>>
    ).detail

    request.headers.set('X-Tab-Identifier', tabIdentifier)
  },
  { capture: true }
)
