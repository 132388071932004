import { Application, ControllerConstructor } from 'stimulus'
import { install } from './resolver'
import MaskController from './mask_controller'

const application = Application.start()

application.register('mask', MaskController)

const context = import.meta.glob<
  false,
  string,
  { default: ControllerConstructor }
>('@/controllers/**/*.ts')

install(application, context)
