import { Controller } from 'stimulus'
import { readInheritableStaticArrayValues } from '@stimulus/core/dist/inheritable_statics'
import { capitalize } from '@stimulus/core/dist/string_helpers'

type Constructor<T> = new (...args: any[]) => T

/** @hidden */
export function QueryPropertiesBlessing<T>(constructor: Constructor<T>) {
  const queries = readInheritableStaticArrayValues(constructor, 'queries')
  return queries.reduce((properties, targetDefinition) => {
    return Object.assign(
      properties,
      propertiesForQueryDefinition(targetDefinition)
    )
  }, {} as PropertyDescriptorMap)
}

function propertiesForQueryDefinition(name: string) {
  const key = `${name}Query`

  return {
    [key]: {
      get(this: Controller) {
        const query = this.data.get(key)

        if (query) {
          const result = calculateQuery(query)

          if (result !== undefined) {
            return result
          }

          throw new Error(`Bad media query "${this.identifier}.${name}"`)
        }

        return false
      },
    },

    [`has${capitalize(key)}`]: {
      get(this: Controller) {
        return this.data.has(`${name}Query`)
      },
    },
  }
}

function calculateQuery(query: string) {
  const [sign, width] = query.split(' ')

  if (width) {
    if (sign === '<') {
      return window.screen.width < parseInt(width)
    } else if (sign === '>') {
      return window.screen.width > parseInt(width)
    }
  } else {
    if (sign === 'true') {
      return true
    } else if (sign === 'false') {
      return false
    } else {
      return parseInt(sign) === window.screen.width
    }
  }
}
