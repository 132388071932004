import { Toast } from '@/store/toasts'
import { client_settings_path } from '@/routes'

export const buildStatusNotice = (
  status: string,
  order: Record<string, string>,
  instrument: Record<string, string>
): Toast | void => {
  const notice = {
    kind: '',
    text: '',
    delay: 5000,
  }

  const kind = () => {
    switch (instrument.kind) {
      case 'share':
        return 'Акции'
      case 'bond':
        return 'Облигации'
      default:
        return ''
    }
  }

  const its_buy = order.act === 'buy'

  switch (status) {
    case 'active':
      notice.kind = 'success'
      notice.text = `<div>${kind()} ${instrument.name}: заявка на ${
        its_buy ? 'покупку' : 'продажу'
      } принята</div>`
      break
    case 'filled':
      notice.kind = 'success'
      notice.text = `<div>${kind()} ${instrument.name} ${
        its_buy ? 'куплены' : 'проданы'
      }</div>`
      break
    case 'rejected':
      notice.kind = 'error'
      notice.text = `<div>${kind()} ${instrument.name}: заявка на ${
        its_buy ? 'покупку' : 'продажу'
      } отклонена брокером</div>`
      break
    case 'failed':
      notice.kind = 'error'
      notice.text = `
        <div>${kind()} ${instrument.name} не удалось ${
        its_buy ? 'купить' : 'продать'
      }</div>
        <small>Возникла ошибка.</small>
      `
      notice.delay = 30000
      break
    case 'cancelled':
      notice.kind = 'error'
      notice.text = `<div>${kind()} ${instrument.name}: заявка на ${
        its_buy ? 'покупку' : 'продажу'
      } отменена</div>`
      break
    default:
      return
  }

  if (status !== 'active' && order.status_info?.length) {
    const dot = order.status_info.slice(-1) == '.' ? '' : '.'
    notice.text += `<small>${order.status_info}${dot}</small>`
  }

  return notice
}

export const buildCancelRejectNotice = (reason: string): Toast => {
  return {
    kind: 'error',
    text: `
      <div>Не удалось отменить заявку</div>
      <small>${reason}</small>
    `,
    delay: 5000,
  }
}

export const buildUnknownErrorNotice = (reason: string): Toast => {
  return {
    kind: 'error',
    text: `
      <div>Непредвиденная ошибка</div>
      <small>${reason}</small>
    `,
    autoclose: false,
  }
}

export const buildNeedQualTestNotice = (reason: string): Toast => {
  return {
    kind: 'error',
    text: `
      <div>Необходимо пройти тестирование на сайте брокера</div>
      <small>Сообщение от брокера: ${reason}</small>
    `,
    autoclose: false,
  }
}

export const buildChangePasswordNotice = () => {
  return {
    kind: 'error',
    text: `
      <p class="mb-15">Необходимо сменить временный пароль на постоянный</p>
      <wds-btn kind="dark" type="link" block="true" slot="action" href="${client_settings_path()}">Подробнее</wds-btn>
    `,
    autoclose: false,
  }
}

export const buildCancellingOrderNotice = () => {
  return {
    kind: 'success',
    text: `
      <p class="mb-15">Запрос на отмену заявки отправлен брокеру. Ожидайте ответа.</p>
    `,
    delay: 5000,
  }
}
