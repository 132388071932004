import { consumer } from './loki_consumer'
import { push, Toast } from '@/store/toasts'
import {
  buildStatusNotice,
  buildCancelRejectNotice,
  buildChangePasswordNotice,
  buildUnknownErrorNotice,
  buildNeedQualTestNotice
} from '@/factories/trading_notice'

type Data = {
  event: string
  status?: string
  error?: string
  reason?: string
  order?: Record<string, any>
  instrument?: Record<string, any>
}

consumer?.subscriptions.create('NoticesChannel', {
  received(data: Data) {
    let notice: Toast
    switch (data.event) {
      case 'order_status_changed':
        notice = buildStatusNotice(data.status, data.order, data.instrument)
        break
      case 'order_cancel_reject':
        notice = buildCancelRejectNotice(data.reason)
        break
      case 'need_change_password':
        notice = buildChangePasswordNotice()
        break
      case 'unknown_error':
        notice = buildUnknownErrorNotice(data.error)
        break
      case 'need_qual_test':
        notice = buildNeedQualTestNotice(data.error)
        break
    }
    if (notice) {
      push(notice)
    }
  },
})
