import ApplicationController from './application_controller'
import Inputmask from 'inputmask'

Inputmask.extendAliases({
  russianPhone: {
    mask: '999 999-99-99',
  },
})

Inputmask.extendDefinitions({
  ".": {
    validator: "."
  }
})

export default class extends ApplicationController {
  static values = {
    params: Object,
  }

  declare paramsValue: Inputmask.Options

  get input() {
    if (this.element.hasAttribute('contenteditable'))
      return this.element as HTMLElement

    return this.element.querySelector<HTMLInputElement | HTMLTextAreaElement>(
      'input,textarea'
    )!
  }

  connect() {
    if (this.input === this.element) return this.initMask()

    this.element.addEventListener('didLoad', this.initMask)
  }

  disconnect() {
    this.element.addEventListener('didLoad', this.initMask)
  }

  initMask = () => {
    if (this.input.inputmask) return

    const mask = new Inputmask({
      jitMasking: true,
      clearMaskOnLostFocus: true,
      showMaskOnHover: false,
      showMaskOnFocus: false,
      ...this.paramsValue,
    })
    mask.mask(this.input)

    this.input.addEventListener('paste', this.handlePaste, { capture: true })

    this.element.addEventListener('focus', (e) => e.stopPropagation(), { capture: true })

    // Ломается поведение флетпикера когда вызывается stopPropagation.
    this.element.addEventListener('click', (e) => {
      e.stopPropagation()
      if (this.element._flatpickr) this.element._flatpickr?.open()
    }, { capture: true })

    setTimeout(() => {
      if (this.input.inputmask?.isValid()) {
        void this.element.closest('wds-form-control')?.setCustomValidity()
      }
    })

    setTimeout(() => {
      const el = this.element as HTMLWdsInputElement
      el.value = el.value || ''
    }, 50)
  }

  stopPropagation(e: Event) {
    e.stopPropagation()
  }

  handlePaste = (e: ClipboardEvent) => {
    const clipboardData = e.clipboardData?.getData('text')

    if (
      !clipboardData ||
      !(
        this.input instanceof HTMLInputElement ||
        this.input instanceof HTMLTextAreaElement
      ) ||
      this.input.selectionStart === null ||
      this.input.selectionEnd === null ||
      !this.input.inputmask
    )
      return

    e.stopPropagation()
    e.preventDefault()

    const value = this.beforePaste(clipboardData)

    if (value === false) return

    const valueBeforeSelection = this.input.value.substring(
      0,
      this.input.selectionStart
    )
    const valueAfterSelection = this.input.value.substring(
      this.input.selectionEnd
    )

    const pastedValue = valueBeforeSelection + value + valueAfterSelection

    this.input.inputmask.setValue(pastedValue)

    this.input.dispatchEvent(
      new Event('input', {
        bubbles: true,
        cancelable: true,
      })
    )
  }

  beforePaste = (value: string): boolean | string => {
    if (['decimal', 'numeric'].includes(this.input.inputmask?.option('alias')) ) value = value.trim().replace(/\,/g, '.')
    if (this.input.inputmask?.option('alias') === 'russianPhone') {
      value = value.trim().replace(/[^\d]/g, '')
      if (['7', '8'].includes(value[0])) {
        value = value.substring(1)
      }

      value = value.substring(0, 10)
    }

    return value
  }
}
